// VendorLeftSection.jsx
import React from "react";
import { Link } from "react-router-dom";
import { FaShoppingBag, FaClipboardList } from "react-icons/fa"; // Import icons for vendor options
import { RiLogoutCircleLine } from "react-icons/ri";
const VendorLeftSection = ({ handleOptionClick }) => {
  const handleLogout = () => {
    // Clear the admin token from localStorage
    localStorage.removeItem("vendorToken");
    // Redirect to the home page
  };

  const vendorOptions = [
    {
      name: "Coupons",
      icon: <FaShoppingBag />,
      path: "/vendordashboard/coupons",
      onClickValue: "coupons",
    },
    {
      name: "Coupon expiry",
      icon: <FaClipboardList />,
      path: "/vendordashboard/couponexpiry",
      onClickValue: "couponexpiry",
    },
    {
      name: "Your products",
      icon: <FaClipboardList />,
      path: "/vendordashboard/products",
      onClickValue: "products",
    },
    {
      name: "Add Product",
      icon: <FaClipboardList />,
      path: "/vendordashboard/addproduct",
      onClickValue: "addproduct",
    },
    {
      name: "Create Deal",
      icon: <FaClipboardList />,
      path: "/vendordashboard/createsmartdeals",
      onClickValue: "createsmartdeals",
    },
    {
      name: "Smart Deals",
      icon: <FaClipboardList />,
      path: "/vendordashboard/smartdeals",
      onClickValue: "smartdeals",
    },
    {
      name: "Log Out",
      icon: <RiLogoutCircleLine />,
      path: "/",
      onClickValue: "logout",
      isLogout: true,
    },
  ];

  return (
    <div className="w-1/6 h-[100vh] p-4 shadow-xl">
      <h2 className="text-lg font-bold mb-4 text-center">Options</h2>
      <ul className="text-[18px]">
        {vendorOptions.map((option, index) => (
          <li
            key={index}
            className="mb-2 duration-200 p-2 rounded-lg hover:text-white hover:bg-gradient-to-r from-orange-400 to-yellow-400"
          >
            <Link
              to={option.path}
              className="flex items-center"
              onClick={() =>
                option.isLogout
                  ? handleLogout()
                  : handleOptionClick(option.onClickValue)
              }
            >
              {option.icon} &nbsp; {option.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default VendorLeftSection;
