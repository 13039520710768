import React, { useState } from "react";
import axiosClient from "../components/AxiosClient";
import { RxCross2 } from "react-icons/rx";

const Createsmartdeals = () => {
  const [productId, setProductId] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [message, setMessage] = useState("");
  const [openCreate, setOpenCreate] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Retrieve vendor token from local storage
      const vendorToken = localStorage.getItem("vendorToken");

      // Check if vendor token exists
      if (vendorToken) {
        // Create a FormData object and append form values
        const formData = new FormData();
        formData.append("product_id", productId);
        formData.append("discount_percentage", discountPercentage);
        formData.append("start_date", new Date(startDate).toISOString());
        formData.append("end_date", new Date(endDate).toISOString());

        const response = await axiosClient.post(
          "smartdeals/create_deal",
          formData,
          {
            headers: {
              Authorization: `Bearer ${vendorToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setMessage("Deal created successfully!");
      } else {
        console.error("Vendor token not found in local storage");
        setMessage("Vendor token not found. Please log in.");
      }
    } catch (error) {
      console.error("Error creating deal:", error);
      setMessage("Error creating deal: " + error.message);
    }
  };

  const handleCreateDeal = () => {
    setOpenCreate(!openCreate);
  };

  const formFields = [
    {
      label: "Product ID:",
      type: "text",
      value: productId,
      onChange: (e) => setProductId(e.target.value),
    },
    {
      label: "Discount Percentage:",
      type: "number",
      value: discountPercentage,
      onChange: (e) => setDiscountPercentage(e.target.value),
    },
    {
      label: "Start Date:",
      type: "date",
      value: startDate,
      onChange: (e) => setStartDate(e.target.value),
    },
    {
      label: "End Date:",
      type: "date",
      value: endDate,
      onChange: (e) => setEndDate(e.target.value),
    },
  ];

  return (
    <div>
      <div className="pb-4 flex justify-between items-center">
        <h1 className="text-[#fa923c] font-semibold text-lg ">Smart Deals</h1>
        <button
          className="bg-gradient-to-r from-orange-400 to-yellow-400 p-3 rounded-md text-white"
          onClick={handleCreateDeal}
        >
          Create Deal
        </button>
        {openCreate && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center border-orange-500">
            <div className="bg-white p-4 rounded w-fit h-fit flex flex-col justify-center items-center gap-4 border border-orange-500 ">
              <form onSubmit={handleSubmit}>
                <div className="flex justify-between py-3">
                  <h2 className="text-lg text-[#fa923c] font-semibold">
                    Create Smart Deal
                  </h2>
                  <button className="text-lg" onClick={handleCreateDeal}>
                    <RxCross2 />
                  </button>
                </div>
                <div className=" flex flex-col gap-4">
                  {formFields.map((field, index) => (
                    <div key={index} className="flex gap-2">
                      <label className="flex gap-2">
                        <h4 className="w-44">{field.label}</h4>
                        <input
                          className="border"
                          type={field.type}
                          value={field.value}
                          onChange={field.onChange}
                          required
                        />
                      </label>
                      <br />
                    </div>
                  ))}
                </div>

                <br />
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="bg-gradient-to-r from-orange-400 to-yellow-400 p-2 rounded-md text-white"
                  >
                    Create Deal
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>

      <hr></hr>

      {message && (
        <p
          className=" text-center
      p-4 text-red-600"
        >
          {message}
        </p>
      )}
    </div>
  );
};

export default Createsmartdeals;
