import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosClient from "../components/AxiosClient";

const VendorCoupons = () => {
  const [couponsData, setCouponsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterUsed, setFilterUsed] = useState(null);
  const [selectedCoupon, setSelectedCoupon] = useState(null); // State for selected coupon

  useEffect(() => {
    const fetchCoupons = async () => {
      setIsLoading(true);
      try {
        const vendorToken = localStorage.getItem("vendorToken"); // Get vendor token from localStorage
        const formData = new FormData();
        formData.append(
          "used",
          filterUsed !== null ? filterUsed.toString() : "None"
        );
        formData.append("order", "ascending"); // Order parameter set to 'ascending'
        formData.append("coupon_id", "None"); // Add coupon_id parameter with value 'None'
        formData.append("product_id", "None"); // Add product_id parameter with value 'None'

        const response = await axiosClient.post(
          "/coupons/get_coupons_vendor", // Update API endpoint for vendor
          formData,
          {
            headers: {
              Authorization: `Bearer ${vendorToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        setCouponsData(response.data.data[0]);
      } catch (error) {
        console.error("Error fetching coupons:", error);
      }
      setIsLoading(false);
    };

    fetchCoupons();
  }, [filterUsed]);

  const handleFilterUsed = (usedStatus) => {
    setFilterUsed(usedStatus);
  };

  const handleViewDetails = (coupon) => {
    setSelectedCoupon(coupon);
  };

  const handleClosePopup = () => {
    setSelectedCoupon(null);
  };

  return (
    <div className="container mx-auto">
      <h1 className="text-[#fa923c] font-semibold text-lg py-3">Coupons</h1>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <div className="flex justify-between font-semibold border-b border-gray-300 pb-2">
            <div>User Name</div>
            <div>Vendor Name</div>
            <div>Product Name</div>
            <div>Discount Value</div>
            <div>View</div>
          </div>
          {couponsData.map((coupon, index) => (
            <div key={index} className="border border-gray-200 p-4 my-4">
              <div className="flex justify-between items-center">
                <div>{coupon.user_name}</div>
                <div>{coupon.vendor_name}</div>
                <div>{coupon.product_name}</div>
                <div>{coupon.discount_value}</div>
                <button onClick={() => handleViewDetails(coupon)}>View</button>
              </div>
            </div>
          ))}
        </div>
      )}
      {/* Popup for displaying coupon details */}
      {selectedCoupon && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-md">
            <div className="mb-4">
              <h2 className="text-xl font-semibold">Coupon Details</h2>
            </div>
            <div>
              <p>User Name: {selectedCoupon.user_name}</p>
              <p>Vendor Name: {selectedCoupon.vendor_name}</p>
              <p>Product Name: {selectedCoupon.product_name}</p>
              <p>Discount Value: {selectedCoupon.discount_value}</p>
              <p>Discount: {selectedCoupon.discount}</p>
              <p>Discounted Price: {selectedCoupon.discounted_price}</p>
              <p>Expiry: {selectedCoupon.expiry}</p>
              <p>Is Used: {selectedCoupon.is_used.toString()}</p>
              <p>Quantity: {selectedCoupon.quantity}</p>
              {/* Add more details here */}
            </div>
            <div className="mt-4 text-right">
              <button
                onClick={handleClosePopup}
                className="bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded-md focus:outline-none focus:bg-gray-500"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-between py-6">
        <button
          onClick={() => handleFilterUsed("True")}
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md focus:outline-none focus:bg-blue-600 mr-4"
        >
          Used
        </button>
        <button
          onClick={() => handleFilterUsed("False")}
          className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md focus:outline-none focus:bg-green-600"
        >
          Not Used
        </button>
      </div>
    </div>
  );
};

export default VendorCoupons;
