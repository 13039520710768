// VendorDashboard.jsx
import React from "react";
import { Route, Routes } from "react-router-dom"; // Import Routes
import VendorLeftSection from "./VendorLeftSection"; // Import VendorLeftSection component

const VendorDashboard = ({ children }) => {
  const handleOptionClick = (option) => {
    // Handle option click if needed
  };

  return (
    <div className="flex">
      {/* Left Section */}
      <VendorLeftSection handleOptionClick={handleOptionClick} />

      {/* Right Section */}
      <div className="w-5/6">
        <div className="p-4">
          {/* Use Routes to render child routes */}
          <Routes>
            {children} {/* Render the child routes */}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default VendorDashboard;
