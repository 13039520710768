import React, { useState, useEffect, useCallback } from "react";
import axiosClient from "../components/AxiosClient";
import { IoIosArrowForward } from "react-icons/io";
import { LuFilter } from "react-icons/lu";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";

const VendorProducts = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [activeProductId, setActiveProductId] = useState(null);
  const [products, setProducts] = useState([]);
  const [name, setName] = useState("");
  const [saltExpiryDate, setSaltExpiryDate] = useState("");
  const [minPrice, setMinPrice] = useState(0.0);
  const [maxPrice, setMaxPrice] = useState(0.0);
  const [minDiscount, setMinDiscount] = useState(0.0);
  const [maxDiscount, setMaxDiscount] = useState(0.0);
  const [showMRPModal, setShowMRPModal] = useState(false);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [newMRP, setNewMRP] = useState("");
  const [newDiscount, setNewDiscount] = useState("");

  const editMRP = (productId) => {
    setCurrentProduct(productId);
    setShowMRPModal(true);
  };

  const editDiscount = (productId) => {
    setCurrentProduct(productId);
    setShowDiscountModal(true);
  };
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const toggleEdit = (id) => {
    setActiveProductId((prevId) => (prevId === id ? null : id));
  };

  const filters = [
    {
      label: "Min Price",
      type: "number",
      value: minPrice,
      onChange: (e) => setMinPrice(e.target.value),
      placeholder: "Min Price",
    },
    {
      label: "Max Price",
      type: "number",
      value: maxPrice,
      onChange: (e) => setMaxPrice(e.target.value),
      placeholder: "Max Price",
    },
    {
      label: "Min Discount",
      type: "number",
      value: minDiscount,
      onChange: (e) => setMinDiscount(e.target.value),
      placeholder: "Min Discount",
    },
    {
      label: "Max Discount",
      type: "number",
      value: maxDiscount,
      onChange: (e) => setMaxDiscount(e.target.value),
      placeholder: "Max Discount",
    },
  ];

  const handleSubmitMRP = useCallback(async () => {
    if (!newMRP || isNaN(newMRP)) {
      alert("Please enter a valid MRP.");
      return;
    }

    try {
      const vendorToken = localStorage.getItem("vendorToken");
      if (vendorToken) {
        const headers = {
          Authorization: `Bearer ${vendorToken}`,
          "Content-Type": "multipart/form-data", // Set content type for FormData
        };

        const formData = new FormData();
        formData.append("new_mrp", parseFloat(newMRP));

        await axiosClient.post(
          `/products/vendor_edit_mrp/${currentProduct}`,
          formData,
          { headers }
        );

        setShowMRPModal(false);
        setNewMRP("");
        fetchProducts();
      }
    } catch (error) {
      console.error("Error updating MRP:", error);
    }
  }, [newMRP, currentProduct]);
  const handleSubmitDiscount = useCallback(async () => {
    if (!newDiscount || isNaN(newDiscount)) {
      alert("Please enter a valid discount.");
      return;
    }

    try {
      const vendorToken = localStorage.getItem("vendorToken");
      if (vendorToken) {
        const headers = {
          Authorization: `Bearer ${vendorToken}`,
          "Content-Type": "multipart/form-data", // Set content type for FormData
        };

        const formData = new FormData();
        formData.append("discount", parseFloat(newDiscount));

        await axiosClient.post(
          `/products/vendor_discount/${currentProduct}`,
          formData,
          { headers }
        );

        setShowDiscountModal(false);
        setNewDiscount("");
        fetchProducts();
      }
    } catch (error) {
      console.error("Error updating discount:", error);
    }
  }, [newDiscount, currentProduct]);

  const fetchProducts = async () => {
    try {
      const vendorToken = localStorage.getItem("vendorToken");
      if (vendorToken) {
        let formData = null;
        const headers = {
          Authorization: `Bearer ${vendorToken}`,
        };

        if (
          name ||
          saltExpiryDate ||
          minPrice ||
          maxPrice ||
          minDiscount ||
          maxDiscount
        ) {
          formData = new FormData();
          if (name) formData.append("name", name);
          if (saltExpiryDate)
            formData.append("salt_expiry_date", saltExpiryDate);
          if (minPrice) formData.append("min_price", parseFloat(minPrice));
          if (maxPrice) formData.append("max_price", parseFloat(maxPrice));
          if (minDiscount)
            formData.append("min_discount", parseFloat(minDiscount));
          if (maxDiscount)
            formData.append("max_discount", parseFloat(maxDiscount));
          headers["Content-Type"] = "multipart/form-data";
        }

        const response = await axiosClient.post(
          "/products/get_vendor_products",
          formData,
          { headers }
        );
        setProducts(response.data || []);
      } else {
        console.error("Vendor token not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching vendor products:", error);
    }
  };
  useEffect(() => {
    // Fetch products data from the API
    fetchProducts();
  }, [name, saltExpiryDate, minPrice, maxPrice, minDiscount, maxDiscount]);

  return (
    <div>
      <h2 className="text-[#fa923c] font-semibold text-lg flex gap-2  items-center pb-4">
        Vendor Products{" "}
        <span className="flex items-center text-black text-lg font-semibold gap-2">
          <IoIosArrowForward /> Inventory
        </span>
      </h2>
      <hr />
      <div className="flex justify-between items-center w-full py-4">
        <div className="border w-[33rem] rounded-3xl p-2">
          <input
            type="text"
            className=" "
            onChange={(e) => setName(e.target.value)}
            placeholder="Search..."
          />
        </div>

        <div className="w-full flex justify-end gap-6">
          <div className="border p-2 rounded-md">
            <label className="font-medium">
              Salt Expiry Date:
              <input
                type="date"
                className=" "
                onChange={(e) => setSaltExpiryDate(e.target.value)}
              />
            </label>
          </div>
          <div
            className="relative flex justify-end items-center gap-1 "
            onClick={togglePopup}
          >
            <LuFilter /> <span>Filter</span>
          </div>
          {/* Popup */}
          {isPopupOpen && (
            <div className="absolute mt-2  w-fit  bg-white border border-gray-300 rounded-md shadow-lg p-4 z-10">
              {/* Popup content */}
              <div className="flex justify-end">
                <button onClick={togglePopup}>
                  <RxCross2 />
                </button>
              </div>
              <div className="flex gap-4">
                {filters.map((filter, index) => (
                  <div key={index}>
                    <label className="flex flex-col whitespace-nowrap gap-2">
                      {filter.label}:
                      <input
                        type={filter.type}
                        className="border outline-none vendor-input"
                        value={filter.value}
                        onChange={filter.onChange}
                        placeholder={filter.placeholder}
                      />
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <table className="table-auto w-full text-left border-collapse">
        <thead>
          <tr>
            <th className="border p-4">Image</th>
            <th className="border p-4">Name</th>
            <th className="border p-4">MRP</th>
            <th className="border p-4">Discount</th>
            <th className="border p-4">Edit</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr>
              <td className="border p-4">
                <img
                  src={
                    product.image
                      ? `data:image/jpeg;base64,${product.image.image_base64}`
                      : "placeholder.jpg"
                  }
                  alt="Product"
                  className="w-24"
                />
              </td>
              <td className="border p-4">{product.name}</td>
              <td className="border p-4">{product.Mrp}</td>
              <td className="border p-4">{product.discount}</td>
              <td className="border p-4">
                <button onClick={() => toggleEdit(product.object_id)}>
                  <BsThreeDotsVertical />
                </button>
              </td>
              {/* Popup content */}
              {activeProductId === product.object_id && (
                <div className="absolute right-0 mt-9 mr-4 w-48 bg-white border rounded-lg shadow-lg">
                  {/* MRP Modal */}
                  <div className="border text-center p-2 text-base font-medium">
                    <h3 onClick={editMRP}>Edit MRP</h3>
                    {showMRPModal && (
                      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white p-4 rounded w-72 h-44 flex flex-col justify-center items-center gap-4">
                          <input
                            type="number"
                            value={newMRP}
                            onChange={(e) => setNewMRP(e.target.value)}
                            placeholder="Enter new MRP"
                            className="border p-2 rounded"
                          />
                          <div className="mt-2">
                            <button
                              className="bg-gradient-to-r from-orange-400 to-yellow-400 text-white py-2 px-4 rounded"
                              onClick={handleSubmitMRP}
                            >
                              Submit
                            </button>
                            <button
                              className="bg-red-500 text-white py-2 px-4 rounded ml-2"
                              onClick={() => setShowMRPModal(false)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="border text-center p-2 text-base font-medium">
                    <h3 onClick={editDiscount}>Edit Discount</h3>
                    {/* Discount Modal */}
                    {showDiscountModal && (
                      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white p-4 rounded w-72 h-44 flex flex-col justify-center items-center gap-4">
                          <input
                            type="number"
                            value={newDiscount}
                            onChange={(e) => setNewDiscount(e.target.value)}
                            placeholder="Enter new discount"
                            className="border p-2 rounded"
                          />
                          <div className="mt-2">
                            <button
                              className="bg-gradient-to-r from-orange-400 to-yellow-400 text-white py-2 px-4 rounded"
                              onClick={handleSubmitDiscount}
                            >
                              Submit
                            </button>
                            <button
                              className="bg-red-500 text-white py-2 px-4 rounded ml-2"
                              onClick={() => setShowDiscountModal(false)}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VendorProducts;
